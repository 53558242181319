import React from "react";
import { FormattedMessage } from "react-intl";
import DecodedButton from "../../../../common/DecodedButton";
import { getInLineUtil } from "../../../../../utils/getInLineUtils";
import { TextMediumStandard } from "../../../../shared/Typography";
import { dateUtil } from "../../../../../utils/date";

const GetInLineAvailability = (props) => {
  const {
    isWithinOperatingHours,
    handleGetInLine,
    walkinSlot,
  } = props;

  const slotsAvailable = walkinSlot === "CURRENTLY AVAILABLE";

  const waitWithinOperatingHours = () =>
    walkinSlot === "CURRENTLY AVAILABLE" ? (
      <TextMediumStandard
        sx={{
          fontStyle: "normal",
          fontWeight: "bold",
          lineHeight: "24px",
          color: "#2D8088",
        }}
      >
        WALK-IN: {walkinSlot}
      </TextMediumStandard>
    ) : (
      <TextMediumStandard
        sx={{
          fontStyle: "normal",
          fontWeight: "bold",
          lineHeight: "24px",
          color: "grey",
        }}
      >
        WALK-IN: UNAVAILABLE
      </TextMediumStandard>
    );

  return (
    <>
      {isWithinOperatingHours && slotsAvailable && waitWithinOperatingHours()}
      <FormattedMessage
        id={
          isWithinOperatingHours && slotsAvailable
            ? "landing.get_in_line"
            : "landing.get_in_line.start"
        }
        values={{
          lineBreak: <br />,
          startTime: getInLineUtil.startTimeDisplay(),
        }}
        defaultMessage={
          isWithinOperatingHours && slotsAvailable
            ? `GET IN LINE NOW`
            : `Virtual line will be available at {startTime}`
        }
      >
        {(txt) => (
          <DecodedButton
            variant="contained"
            onClick={handleGetInLine}
            children={txt}
            disabled={!isWithinOperatingHours || !slotsAvailable}
          />
        )}
      </FormattedMessage>
    </>
  );
};

export default GetInLineAvailability;
