import { Card, styled, CardContent, Grid } from "@mui/material"
import { Loading } from "../../../../../shared-library"

export const LandingOrgCardWrapper = styled(Card)(({ theme, ishovered }) => ({
    boxShadow: ishovered
      ? "0px 0px 5px 2px rgba(0, 128, 128, 0.5)"
      : "0px 1px 3px 1px rgba(0, 0, 0, 0.3)",
    width: "100%",
    minHeight: "350px",
    margin: "0",
    border: ishovered
      ? "1px solid rgba(0, 128, 128, 0.5)"
      : "1px solid transparent",
    borderRadius: "6px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      minHeight: "320px",
    },
  }));
  
  export const StyledCardBody = ({ children, isMobile }) => (
    <CardContent
      id="landing-org-card-body"
      component={Grid}
      gap={isMobile ? 2 : 3}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "1.5em",
      }}
    >
      {children}
    </CardContent>
  );
  
  export const LoadingCard = () => {
    return (
      <Grid
        container
        item
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "100%",
          minHeight: "320px",
        }}
      >
        <Loading message="loading location data" primary />
      </Grid>
    );
  };