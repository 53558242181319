import React, { useState } from "react";
import DecodedButton from "../../../../common/DecodedButton";
import PreDialog from "../../../../shared/Dialog/PreDialog"

const EmergencyAction = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <DecodedButton onClick={handleOpen}>EMERGENCY PATIENTS</DecodedButton>
      {open && (
        <PreDialog showPreDialog={open} handleClosePreDialog={() => setOpen(false)}/>
      )}
    </>
  );
};

export default EmergencyAction;
