import * as React from "react";
import GoogleMapReact from "google-map-react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LandingOrgCard from "./LandingOrgCard";
import { globalBloc } from "../../../global.bloc";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { FormattedMessage } from "react-intl";
import DecodedButton from "../../../common/DecodedButton";
import EastIcon from '@mui/icons-material/East';

const MoreLocationsIndicator = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxShadow: "0px 1px 3px 1px rgba(0, 0, 0, 0.3)",
        borderRadius: "6px",
        padding: "0.5rem",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
      }}
    >
      <Typography sx={{ fontSize: "1.5rem",fontWeight: "bold", color: 'red'}}>
        Scroll for more locations
      </Typography>
      <EastIcon fontSize="large" sx={{ml: 1, color: 'red'}}/>
    </Box>
  );
}

const LandingOrgSelector = ({ organisations }) => {
  const theme = useTheme();

  const [selected, setSelected] = useState(null);
  const [pointTo, setPointTo] = useState(null);

  const history = useHistory();

  useEffect(() => {
    globalBloc.resetGlobalBloc();
  }, []);

  useEffect(() => {
    if (selected) {
      globalBloc.updateBooking(selected);
      globalBloc.updateGlobalBloc({ orgSelected: true });
    }
  }, [selected]);

  const calculateOffsetCenter = (lat, lng, offset) => {
    return {
      lat: lat - offset,
      lng: lng,
    };
  };

  const mapCenter = pointTo
    ? calculateOffsetCenter(
        pointTo?.contactInformation?.address?.geoLocation?.latitude,
        pointTo?.contactInformation?.address?.geoLocation?.longitude,
        isMobile ? 0.05 : 0.02
      )
    : {
        lat: 35.130094,
        lng: -106.5741617,
      };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -1,
        }}
      >
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
            version: "weekly",
            libraries: ["places"],
          }}
          defaultCenter={{
            lat: 35.130094,
            lng: -106.5741617,
          }}
          center={mapCenter}
          defaultZoom={12}
          options={{
            zoomControl: false,
            fullscreenControl: false,
          }}
        >
          {pointTo && (
            <div
              lat={pointTo?.contactInformation?.address?.geoLocation?.latitude}
              lng={pointTo?.contactInformation?.address?.geoLocation?.longitude}
              style={{
                position: "relative",
                transform: "translate(80%, -90%)",
                // width: '36px',
                // height: '36px',
              }}
            >
              <LocationOnIcon
                sx={{
                  fontSize: "36px",
                  color: theme.palette.accent.main,
                }}
                fontSize="large"
                color="primary"
              />
            </div>
          )}
        </GoogleMapReact>
      </div>
      <Grid
        container
        direction="column"
        justifyContent="flex-end"
        height="100%"
        sx={(theme) => ({
          paddingLeft: "5em",
          marginBottom: "12px",
          [theme.breakpoints.down("md")]: {
            paddingLeft: "20px",
          },
        })}
      >
        <Grid
          item
          container
          flexWrap="nowrap"
          width="100%"
         
        >
          <MoreLocationsIndicator/>
        </Grid>
        <Grid
          item
          container
          flexWrap="nowrap"
          sx={{
            overflowX: "scroll",
            maxWidth: "90vw !important",
          }}
        >
          {organisations
            ? organisations.map((org) => (
                <Box
                  key={org.id}
                  onMouseEnter={() => setPointTo(org)}
                  sx={{
                    width: "300px",
                    minWidth: "300px",
                    maxWidth: "300px",
                    margin: "12px 24px 12px 2px",
                  }}
                >
                  <LandingOrgCard
                    organisation={org}
                    key={org.id}
                    isHovered={pointTo?.id === org.id}
                    setSelected={setSelected}
                    pointTo={pointTo}
                  />
                </Box>
              ))
            : null}
        </Grid>
        <Grid
          item
          sx={{
            maxWidth: "88vw !important",
            marginTop: "1rem",
          }}
        >
          <FormattedMessage
            id="landing.manage"
            defaultMessage="MANAGE RESERVATION OR PLACE IN LINE"
          >
            {(txt) => (
              <DecodedButton
                variant="contained"
                fullWidth
                onClick={() => {
                  sessionStorage.setItem("action", "manage-appointments");
                  window.location.reload();
                }}
              >
                {txt}
              </DecodedButton>
            )}
          </FormattedMessage>
        </Grid>
      </Grid>
    </>
  );
};

export default LandingOrgSelector;
