import { CardHeader, Stack, styled, Typography } from "@mui/material";
import { providerUtil } from "../../../../../utils/provider";
import { useIsMobile } from "../../../../../shared-library"

const StyledOrgName = styled(Typography)({
  fontWeight: "800 !important",
  lineHeight: "18px",
  color: "#5C5D5F",
});

const StyledOrgAddress = styled(Typography)({
  fontSize: "1.2rem !important",
  color: "grey",
  fontWeight: "500 !important",

});

const CardTitle = ({ organisation }) => {
  const isMobile = useIsMobile();

  if (!organisation || !organisation.name) {
    return null;
  }

  return (
    <CardHeader
      sx={{ padding: "1.5em 1.5em 0 1.5em" }}
      title={
        <Stack spacing={1}>
          <StyledOrgName variant={isMobile ? "h4" : "h5"}>{organisation.name.toUpperCase()}</StyledOrgName>
          <StyledOrgAddress>
            {providerUtil.formatOrganisationAddress(organisation)}
          </StyledOrgAddress>
        </Stack>
      }
    />
  );
};

export default CardTitle;
